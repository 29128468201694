import api from '../utils/api'
import uniq from 'lodash/uniq'

const BASE_URL = 'tax-deductions'

function processListItems (items) {
  const getUniq = (objectsList, key) => {
    const items = objectsList.map((item) => item[key]).filter((item) => item)
    return uniq(items)
  }
  return items.map((item) => ({
    ...item,
    legalEntities: getUniq(item.certificates, 'legalEntity').join(', ') || '—'
  }))
}

export default {
  get (symptomId) {
    return api.get(`${BASE_URL}/${symptomId}`)
  },
  async getAll (params) {
    const data = await api.get(BASE_URL, { params: params })
    return {
      ...data,
      items: processListItems(data.items)
    }
  },
  getLegalEntityNames () {
    return api.get(`${BASE_URL}/legal-entity-names`)
  }
}
