<template>
  <v-chip
    class="ma-2"
    :color="color"
    label
    outlined
  >
    {{ message }}
  </v-chip>
</template>

<script>
  export default {
    props: {
      value: {type: String, required: true},
    },
    computed: {
      message () {
        switch (this.value) {
          case 'created':
            return 'Создано'
          case 'error':
            return 'Ошибка'
          case 'success':
            return 'Успешно'
        }

        return this.value
      },
      color () {
        switch (this.value) {
          case 'created':
            return 'primary'
          case 'error':
            return 'red'
          case 'success':
            return 'green'
        }

        return 'orange'
      }
    }
  }
</script>
