<template>
  <item-edit-view
    title="Налоговый вычет"
    :loading="loading"
  >
    <v-card v-if="!loading && item">
      <v-tabs
        v-model="tab"
      >
        <v-tab key="info">Инфо</v-tab>
        <v-tab key="certificates">Справки</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="info">
          <v-card
            flat
          >
            <v-card-text>
              <v-row class="ma-0 px-2">
                <v-col cols="3" sm="2"><b>Создан</b></v-col>
                <v-col cols="9" sm="10">
                  {{ item.createdAt | dateTime }}
                </v-col>
              </v-row>
              <v-row class="ma-0 px-2">
                <v-col cols="3" sm="2"><b>Период</b></v-col>
                <v-col cols="9" sm="10"
                >
                  {{ item.period.start | date }} - {{ item.period.end | date }}
                </v-col>
              </v-row>
              <v-row class="ma-0 px-2">
                <v-col cols="3" sm="2"><b>Статус</b></v-col>
                <v-col cols="9" sm="10"
                >
                  <status :value="item.status"></status>
                </v-col>
              </v-row>
              <v-row class="ma-0 px-2">
                <v-col cols="3" sm="2"><b>Плательщик</b></v-col>
                <v-col cols="9" sm="10">
                  {{ item.payer | fullName }}<br/>
                  <span v-if="item.payer.birthday">{{ item.payer.birthday | date }}</span><br/>
                  <span v-if="item.payer.inn">ИНН: {{ item.payer.inn }}</span>
                  <template v-if="item.payer.document">
                      <br/>
                      <span>Тип документа: {{ item.payer.document.type }}</span><br/>
                      <span>Номер: {{ item.payer.document.number }}</span><br/>
                      <span>Дата выдачи: {{ item.payer.document.issuedDate | date }}</span>
                  </template>
                </v-col>
              </v-row>
              <v-row class="ma-0 px-2" v-if="!item.isLegacy">
                <v-col cols="3" sm="2">
                  <b>Плательщик является пациентом</b>
                </v-col>
                <v-col cols="9" sm="10">
                  {{ item.payerIsPatient ? 'Да' : 'Нет' }}
                </v-col>
              </v-row>
              <v-row class="ma-0 px-2">
                <v-col cols="3" sm="2">
                  <b>Пациент</b>
                </v-col>
                <v-col cols="9" sm="10">
                  <a
                    :href="`https://iskra.chaika.com/patient/${item.patient.ehrRecordId}`"
                    target="_blank"
                  >{{ item.patient.ehrRecordId }}
                  </a>
                  {{ item.patient | fullName }}<br/>
                  {{ item.patient.birthday | date }}<br/>
                  <span v-if="item.patient.inn">ИНН: {{ item.patient.inn }}</span>
                  <template v-if="item.patient.document">
                    <br/>
                    <span>Тип документа: {{ item.patient.document.type }}</span><br/>
                    <span>Номер: {{ item.patient.document.number }}</span><br/>
                    <span>Дата выдачи: {{ item.patient.document.issuedDate | date }}</span>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item key="certificates">
          <v-card
            flat
          >
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">№</th>
                    <th class="text-left">Год</th>
                    <th class="text-left" v-if="item.isLegacy">Код услуги</th>
                    <th class="text-left">Юр. лицо</th>
                    <th class="text-left">Сумма, ₽</th>
                    <th class="text-left">Файл (с печатью)</th>
                    <th class="text-left">Файл (без печати)</th>
                    <th class="text-left">Оказанные услуги</th>
                  </tr>
                  </thead>
                  <tbody style="vertical-align: baseline">
                  <tr
                    v-for="cert in item.certificates" :key="cert.id"
                  >
                    <td>{{ cert.id }}</td>
                    <td>{{ cert.year }}</td>
                    <td v-if="item.isLegacy">0{{ cert.codeOfService }}</td>
                    <td>{{ cert.legalEntity }}</td>
                    <td>{{ cert.total / 100 }}</td>
                    <td>
                      <a v-if="cert.fileUrl" :href="cert.fileUrl" target="_blank">
                        Скачать
                      </a>
                      <span v-else>-</span>
                    </td>
                    <td>
                      <a v-if="cert.fileWithoutFacsimileUrl" :href="cert.fileWithoutFacsimileUrl" target="_blank">
                        Скачать
                      </a>
                      <span v-else>-</span>
                    </td>
                    <td>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                Дата создания
                              </th>
                              <th class="text-left">
                                Название
                              </th>
                              <th class="text-left" v-if="!item.isLegacy">
                                Код услуги
                              </th>
                              <th class="text-left">
                                Сумма
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="rs in cert.renderedServices" :key="rs.misId">
                              <td>{{ rs.createdAt | dateTime }}</td>
                              <td>{{ rs.serviceName }}</td>
                              <td v-if="!item.isLegacy">{{ rs.codeOfService }}</td>
                              <td>{{ rs.amountToPay / 100 }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </item-edit-view>
</template>

<script>
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'
import api from '../../services/tax-deduction'
import Status from './Status'

export default {
  mixins: [createOrUpdateViewMixin(api)],
  components: { Status },
  data () {
    return {
      tab: null
    }
  }
}
</script>
